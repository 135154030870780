// src/App.tsx

import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import Home from "./pages/Home";
import MontajNoktalari from "./pages/MontajNoktalari";
import MontajBasvuruForm from "./pages/MontajBasvuruForm";
import AracMontajBasvuru from "./pages/AracMontajBasvuru";
import UttsNedir from "./pages/UttsNedir";
import NedenKitTakOrtak from "./pages/NedenKitTakOrtak";
import SıkSorulanSorular from "./pages/SıkSorulanSorular";
import MusteriTemsilcisi from "./pages/MusteriTemsilcisi";
import Admin from "./pages/Admin";
import BasvuruDetay from "./pages/BasvuruDetay";
import KvkkPage from "./pages/KVKK";
import Mevzuat from "./pages/Mevzuat";
import Hakkimizda from "./pages/Hakkimizda";
import Gizlilik from "./pages/Gizlilik";
import { createGlobalStyle } from "styled-components";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import PageViewTracker from "./components/PageViewTracker";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Inter', 'Roboto', sans-serif;
  }
`;

const theme = createTheme({
  typography: {
    fontFamily: "'Inter', 'Roboto', sans-serif",
  },
});

const GA_MEASUREMENT_ID = "G-RHMGJH0EDY"; // Kendi GA4 Ölçüm Kimliğinizi kullanın

const App: React.FC = () => {
  useEffect(() => {
    ReactGA.initialize(GA_MEASUREMENT_ID);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <PageViewTracker />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/utts-montaj-noktalari"
              element={<MontajNoktalari />}
            />
            <Route
              path="/utts-montaj-basvuru"
              element={<MontajBasvuruForm />}
            />
            <Route path="/utts-kayit-sureci" element={<AracMontajBasvuru />} />
            <Route path="/utts-nedir" element={<UttsNedir />} />
            <Route
              path="/utts-kittak-is-ortagi"
              element={<NedenKitTakOrtak />}
            />
            <Route
              path="/utts-sık-sorulan-sorular"
              element={<SıkSorulanSorular />}
            />
            <Route
              path="/utts-musteri-temsilcisi"
              element={<MusteriTemsilcisi />}
            />
            <Route path="/kittak-admin" element={<Admin />} />
            <Route
              path="/kittak-admin/basvuru/:id"
              element={<BasvuruDetay />}
            />
            <Route path="/kvkk" element={<KvkkPage />} />
            <Route path="/mevzuat" element={<Mevzuat />} />
            <Route path="/hakkimizda" element={<Hakkimizda />} />
            <Route path="/gizlilik" element={<Gizlilik />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
};

export default App;
