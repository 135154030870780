// src/components/MontajBasvuruForm.tsx

import React, { useEffect, useState } from "react";
import axios from "axios"; // Axios'u içe aktarın
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Paper,
  IconButton,
  FormControlLabel,
  Checkbox,
  Switch,
  Button,
} from "@mui/material";
import NearMeIcon from "@mui/icons-material/NearMe";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { City, ICity, IState, State } from "country-state-city";
import ReactGA from "react-ga4";

const MontajBasvuruForm = () => {
  // Form alanları için durum kancaları
  const [firmaIsmi, setFirmaIsmi] = useState("");
  const [yetkiliIsimSoyisim, setYetkiliIsimSoyisim] = useState("");
  const [yetkiliMailAdresi, setYetkiliMailAdresi] = useState("");
  const [ulkeKodu, setUlkeKodu] = useState("TR");
  const [yetkiliTelefonNumarasi, setYetkiliTelefonNumarasi] = useState("");
  const [tasitSayisi, setTasitSayisi] = useState("");
  const [tasitTipi, setTasitTipi] = useState("");
  const [vergiDairesi, setVergiDairesi] = useState("");
  const [vergiNo, setVergiNo] = useState("");
  const [mersisNo, setMersisNo] = useState("");
  const [il, setIl] = useState("");
  const [ilce, setIlce] = useState("");
  const [sokak, setSokak] = useState("");
  const [randevuTarihi, setRandevuTarihi] = useState("");
  const [randevuSaati, setRandevuSaati] = useState("");
  const [darphaneKodAldim, setDarphaneKodAldim] = useState(false);
  const [sartlariKabulEdiyorum, setSartlariKabulEdiyorum] = useState(true);
  const [kvkkOnay, setKvkkOnay] = useState(false);
  const [smsOnay, setSmsOnay] = useState(false);

  const [basvuruAlindi, setBasvuruAlindi] = useState(false);
  const [cities, setCities] = useState<IState[]>([]);
  const [districts, setDistricts] = useState<ICity[]>([]);
  const [selectedCity, setSelectedCity] = useState<IState | null>(null);
  const [selectedDistrict, setSelectedDistrict] = useState<ICity | null>(null);
  const [referansKodu, setReferansKodu] = useState("");

  // Ek durumlar
  const [loading, setLoading] = useState(false); // Yükleniyor durumu
  const [error, setError] = useState<string | null>(null); // Hata durumu

  useEffect(() => {
    const cities = State.getStatesOfCountry("TR");
    console.log("cities", cities);
    const filteredCities = cities.filter((city) => city.isoCode === "34");
    setCities(filteredCities);
  }, []);

  useEffect(() => {
    if (selectedCity) {
      const districts = City.getCitiesOfState("TR", selectedCity.isoCode);
      setDistricts(districts);
    }
  }, [selectedCity]);

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json`,
              {
                params: {
                  latlng: `${latitude},${longitude}`,
                  key: "AIzaSyAA0pL05bGustwOYfrT2-wV5tZ4dp2Pwb0",
                },
              }
            );
            const data = response.data;
            if (data.status === "OK") {
              console.log("data", data);
              const addressComponents = data.results[0].address_components;
              let stateName = "";
              let cityName = "";

              console.log(
                "data.results[0].address_components",
                data.results[0].formatted_address
              );
              setSokak(data.results[0].formatted_address);
              addressComponents.forEach((component: any) => {
                if (component.types.includes("administrative_area_level_1")) {
                  stateName = component.long_name;
                }
                if (component.types.includes("administrative_area_level_2")) {
                  cityName = component.long_name;
                }
              });

              const selectedState = cities.find(
                (s) =>
                  s.name
                    .toLowerCase()
                    .localeCompare(
                      data.results[0].address_components[4].long_name.toLowerCase(),
                      "tr",
                      { sensitivity: "base" }
                    ) === 0
              );
              if (selectedState) {
                console.log("selectedState", selectedState);
                setSelectedCity(selectedState);
                const citiesOfState = City.getCitiesOfState(
                  "TR",
                  selectedState.isoCode
                );
                const selectedCity = citiesOfState.find(
                  (c) =>
                    c.name === data.results[0].address_components[3].long_name
                );
                if (selectedCity) {
                  console.log("selectedCity", selectedCity);
                  setSelectedDistrict(selectedCity);
                }
              }
            } else {
              alert("Adres bulunamadı");
            }
          } catch (error) {
            console.error("Geocode API Hatası:", error);
            alert("Adres bulunurken bir hata oluştu.");
          }
        },
        (error) => {
          console.error(error);
          alert("Konum alınırken hata oluştu");
        }
      );
    } else {
      alert("Tarayıcınız konum hizmetlerini desteklemiyor.");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // Form verilerini topla
    const formData = {
      id: `${Date.now()}`, // Benzersiz bir ID oluşturabilirsiniz
      firmaIsmi,
      yetkiliIsimSoyisim,
      yetkiliTelefon: yetkiliTelefonNumarasi,
      tasitSayisi,
      tasitTipi,
      vergiDairesi,
      vergiNumarasi: vergiNo,
      mersisNo: "empty",
      il: selectedCity?.name || "",
      ilce: selectedDistrict?.name || "",
      adres: "empty",
      randevuTarihi: randevuTarihi ? new Date(randevuTarihi) : new Date(),
      randevuSaati: randevuSaati || "no data",
      darphaneKodAldiMi: darphaneKodAldim,
      referansKodu,
    };

    console.log("Form Data:", formData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/basvuru`, // API endpoint'inizi kontrol edin
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        setBasvuruAlindi(true);
        ReactGA.send({
          hitType: "event",
          eventCategory: "Conversion",
          eventAction: "form_submission",
          eventLabel: "Montaj Basvuru",
          value: 1,
        });
      } else {
        setError("Başvuru gönderilirken beklenmedik bir hata oluştu.");
      }
    } catch (err: any) {
      console.error("API Hatası:", err);
      setError(
        err.response?.data?.message || "Başvuru gönderilirken bir hata oluştu."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box mx={{ xs: 2, sm: 6, md: 6 }} my={4}>
      <Box component="form" onSubmit={handleSubmit}>
        <Header />
        {/* Header Bölümü */}
        <Box
          py={3}
          sx={{
            backgroundColor: "#002B32",
          }}
        >
          <Typography variant="h2" sx={{ color: "#fff", textAlign: "center" }}>
            Montaj Basvuru
          </Typography>
          {/*   <Typography
            mt={2}
            variant="h5"
            sx={{ color: "#ffffffb8", textAlign: "center" }}
          >
            <span style={{ color: "#fff" }}>2 / 2</span> Adım
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Box
              sx={{ ml: 4, flexGrow: 1, height: 4, backgroundColor: "#fff" }}
            />
            <Box
              sx={{ mr: 4, flexGrow: 1, height: 4, backgroundColor: "#fff" }}
            />
          </Box> */}
        </Box>
        {/* Ana İçerik */}

        <Box
          sx={{
            backgroundColor: "#002B32",
            p: { xs: 1, md: 4 },
            pb: { xs: 1, md: 8 },
            borderBottomLeftRadius: "24px",
            borderBottomRightRadius: "24px",
          }}
        >
          <Box
            display="flex"
            flexDirection={{ xs: "column-reverse", md: "row" }}
            justifyContent="center"
            alignItems="flex-start"
            gap={4}
            p={{ xs: 3, md: 4 }}
            bgcolor="white"
            borderRadius={2}
            overflow="hidden"
          >
            {/* Form Bölümü */}
            {basvuruAlindi ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={"center"}
                height={"100%"}
                width={{
                  xs: "100%",
                  sm: "94%",
                  md: "90%",
                  lg: "86%",
                  xl: "76%",
                }}
                mt={{ xs: 0, sm: 15, md: 18, lg: 20, xl: 25 }}
              >
                <Box>
                  <Box display={"flex"} justifyContent={"center"}>
                    <img
                      src={require("../assets/img/basvuru-alindi.png")}
                      alt="Başvuru Alındı"
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: 48,
                        fontWeight: "semibold",
                        mt: 2,
                      }}
                    >
                      Randevu Talebiniz Alınmıştır
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: 24,
                        fontWeight: "medium",
                        mt: 2,
                      }}
                    >
                      Yetkili müşteri temsilcimiz sizinle en kısa zamanda
                      iletişime geçecektir.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                width={{
                  xs: "100%",
                  sm: "94%",
                  md: "90%",
                  lg: "86%",
                  xl: "76%",
                }}
                border={1}
                borderColor="rgba(0, 0, 0, 0.2)"
                borderRadius={2}
                overflow="hidden"
              >
                <Box p={4} bgcolor="white" borderRadius={2}>
                  <Grid container spacing={3}>
                    {/* Firma İsmi */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Firma İsmi"
                          placeholder="Firma İsmi"
                          value={firmaIsmi}
                          onChange={(e) => setFirmaIsmi(e.target.value)}
                          required
                        />
                      </FormControl>
                    </Grid>
                    {/* Yetkili İsim Soyisim */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Yetkili İsim Soyisim"
                          placeholder="Yetkili İsim Soyisim"
                          value={yetkiliIsimSoyisim}
                          onChange={(e) =>
                            setYetkiliIsimSoyisim(e.target.value)
                          }
                          required
                        />
                      </FormControl>
                    </Grid>
                    {/* Yetkili Mail Adresi */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Yetkili Mail Adresi"
                          type="email"
                          placeholder="you@company.com"
                          value={yetkiliMailAdresi}
                          onChange={(e) => setYetkiliMailAdresi(e.target.value)}
                          required
                        />
                      </FormControl>
                    </Grid>
                    {/* Yetkili Telefon Numarası */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <Box display="flex" alignItems="center">
                          <TextField
                            label="Yetkili Telefon Numarası"
                            placeholder="0 (555) 000-0000"
                            sx={{ flexGrow: 1 }}
                            value={yetkiliTelefonNumarasi}
                            onChange={(e) =>
                              setYetkiliTelefonNumarasi(e.target.value)
                            }
                            required
                          />
                        </Box>
                      </FormControl>
                    </Grid>
                    {/* Taşıt Sayısı */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth required>
                        <InputLabel>Taşıt Sayısı</InputLabel>
                        <Select
                          value={tasitSayisi}
                          onChange={(e) => setTasitSayisi(e.target.value)}
                          label="Taşıt Sayısı"
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5+</MenuItem>
                          {/* Daha fazla seçenek ekleyebilirsiniz */}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* Taşıt Tipi */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth required>
                        <InputLabel>Taşıt Tipi</InputLabel>
                        <Select
                          value={tasitTipi}
                          onChange={(e) => setTasitTipi(e.target.value)}
                          label="Taşıt Tipi"
                        >
                          <MenuItem value="Otomobil">Otomobil</MenuItem>
                          <MenuItem value="Motorsiklet">Motorsiklet</MenuItem>
                          <MenuItem value="Kamyon">Kamyon</MenuItem>
                          <MenuItem value="Kamyonet">Kamyonet</MenuItem>
                          <MenuItem value="Minibüs">Minibüs</MenuItem>
                          <MenuItem value="Otobüs">Otobüs</MenuItem>
                          <MenuItem value="Otobüs">Tır</MenuItem>
                          {/* Daha fazla seçenek ekleyebilirsiniz */}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* Vergi Dairesi */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth required>
                        <TextField
                          label="Vergi Dairesi"
                          placeholder="Vergi Dairesi"
                          value={vergiDairesi}
                          onChange={(e) => setVergiDairesi(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    {/* Vergi No */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth required>
                        <TextField
                          label="Vergi No"
                          placeholder="Vergi No"
                          value={vergiNo}
                          onChange={(e) => setVergiNo(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    {/* Mersis No */}
                    {/*    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth required>
                        <TextField
                          label="Mersis No"
                          placeholder="Mersis No"
                          value={mersisNo}
                          onChange={(e) => setMersisNo(e.target.value)}
                        />
                      </FormControl>
                    </Grid> */}

                    {/* İl */}

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth required>
                        <InputLabel>İl</InputLabel>
                        <Select
                          value={selectedCity?.isoCode || ""}
                          onChange={(e) => {
                            const selectedCity = cities.find(
                              (city) => city.isoCode === e.target.value
                            );
                            if (selectedCity) {
                              setSelectedCity(selectedCity);
                              setIl(selectedCity.name);
                              setSelectedDistrict(null); // İl değiştiğinde ilçeyi sıfırla
                            }
                          }}
                          label="İl"
                        >
                          {cities.map((city) => (
                            <MenuItem key={city.isoCode} value={city.isoCode}>
                              {city.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* İlçe */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth required>
                        <InputLabel>İlçe</InputLabel>
                        <Select
                          value={selectedDistrict?.name || ""}
                          onChange={(e) => {
                            const selectedDistrict = districts.find(
                              (ilce) => ilce.name === e.target.value
                            );
                            if (selectedDistrict) {
                              setSelectedDistrict(selectedDistrict);
                              setIlce(selectedDistrict.name);
                            }
                          }}
                          label="İlçe"
                          disabled={!selectedCity}
                        >
                          {districts.map((district) => (
                            <MenuItem key={district.name} value={district.name}>
                              {district.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* Sokak */}
                    {/*        <Grid item xs={12}>
                      <FormControl fullWidth required>
                        <TextField
                          label="Adres"
                          placeholder="Adresinizi giriniz"
                          value={sokak}
                          onChange={(e) => setSokak(e.target.value)}
                        />
                      </FormControl>
                    </Grid> */}
                    {/* Araç Teslim Alma Konumu */}
                    <Grid item xs={12}>
                      <Paper
                        onClick={handleGetLocation}
                        variant="outlined"
                        sx={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          backgroundColor: "#f9f9f9",
                          p: 1,
                          borderRadius: 1,
                        }}
                      >
                        <Typography variant="body1" sx={{ ml: 2 }}>
                          Konum biligisi al
                        </Typography>
                        <IconButton>
                          <NearMeIcon />
                        </IconButton>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Referans"
                          placeholder="Referans(opsiyonel)"
                          value={referansKodu}
                          onChange={(e) => setReferansKodu(e.target.value)}
                        />
                      </FormControl>
                    </Grid>

                    {/* Randevu Tarihi Seçiniz */}
                    {/*   <Grid item xs={12} sm={6}>
                      <FormControl fullWidth required>
                        <TextField
                          label="Randevu Tarihi Seçiniz"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={randevuTarihi}
                          onChange={(e) => setRandevuTarihi(e.target.value)}
                        />
                      </FormControl>
                    </Grid> */}
                    {/* Randevu Saati Seçiniz */}
                    {/*    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth required>
                        <TextField
                          label="Randevu Saati Seçiniz"
                          type="time"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={randevuSaati}
                          onChange={(e) => setRandevuSaati(e.target.value)}
                        />
                      </FormControl>
                    </Grid> */}
                    {/* Darphane aktivasyon kodu aldım */}
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={darphaneKodAldim}
                            onChange={(e) =>
                              setDarphaneKodAldim(e.target.checked)
                            }
                          />
                        }
                        label="Darphane aktivasyon kodu aldım."
                      />
                    </Grid>
                    {/* Checkboxes */}
                    {/*       <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sartlariKabulEdiyorum}
                            onChange={(e) =>
                              setSartlariKabulEdiyorum(e.target.checked)
                            }
                          />
                        }
                        label="Yukarıdaki şartları sağladığımı, ilgili bilgilerin doğru olduğunu ve başvurumun kabul edilmesi halinde bunu teyit eden evrakları temin edeceğimi beyan ederim."
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={kvkkOnay}
                            onChange={(e) => setKvkkOnay(e.target.checked)}
                          />
                        }
                        label={
                          <>
                            <Typography component="span">
                              KVKK yürürlüğündeki{" "}
                            </Typography>
                            <Typography
                              onClick={() => window.open("/gizlilik", "_blank")}
                              component="span"
                              sx={{ textDecoration: "underline" }}
                            >
                              metni
                            </Typography>
                            <Typography component="span">
                              {" "}
                              okudum ve onaylıyorum.
                            </Typography>
                          </>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={smsOnay}
                            onChange={(e) => setSmsOnay(e.target.checked)}
                          />
                        }
                        label="SMS ve E-posta yoluyla bilgilendirme almayı kabul ediyorum. Yeni ürünler, hizmetler ve etkinlikler hakkında bilgilendirme almayı onaylıyorum."
                      />
                    </Grid>
                    {/* Hata Mesajı */}
                    {error && (
                      <Grid item xs={12}>
                        <Typography color="error">{error}</Typography>
                      </Grid>
                    )}
                    {/* Submit Butonu */}
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          borderRadius: 8,
                          px: 8,
                          py: 2,
                          backgroundColor: "#002B32",
                        }}
                        type="submit"
                        disabled={
                          loading ||
                          !sartlariKabulEdiyorum ||
                          !kvkkOnay ||
                          !smsOnay
                        }
                      >
                        {loading ? "Gönderiliyor..." : "Başvuruda Bulun"}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
            {/* Görüntü veya Ek İçerik Bölümü */}
            <Box
              position={"sticky"}
              display="flex"
              borderRadius={2}
              justifyContent={"center"}
              alignItems={"center"}
              mt={{ xs: 0, md: 15 }}
            >
              <img
                width={"100%"}
                src={require("../assets/img/basvuru.png")}
                alt="Montaj Noktaları"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default MontajBasvuruForm;
