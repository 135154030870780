import React from "react";
import {
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box mx={{ xs: 2, sm: 6, md: 6 }} my={4}>
      <Header />
      <Box
        sx={{
          backgroundColor: "#002B32",
          borderBottomLeftRadius: "24px",
          borderBottomRightRadius: "24px",
          position: "relative",
        }}
      >
        <Box py={{ xs: 3, md: 5 }} px={2}>
        {/*   <Typography
            sx={{
              fontSize: isMobile ? 24 : "calc(100vw*60/1920)",
              fontWeight: "bold",
              color: "white",
              textAlign: "center",
            }}
          >
            Türkiye Cumhuriyeti Resmi UTTS Montaj Partneri
          </Typography> */}
          <Typography
            pt={2}
            sx={{
              fontSize: isMobile ? 24 : "calc(100vw*60/1920)",
              fontWeight: "semibold",
              color: "white",
              textAlign: "center",
            }}
          >
            Kit-Tak ile Kolay UTTS
          </Typography>
        </Box>

        <Box
          sx={{
            paddingX: { xs: 2, md: 5 },
            display: "flex",
            gap: 1,
            flexDirection: { xs: "column", md: "row" },
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "row" },
              position: "relative",
            }}
          >
            <Box sx={{ position: "relative" }}>
              <img
                src={require(isMobile
                  ? "../assets/img/homemobile1.png"
                  : "../assets/img/b1.png")}
                width={"100%"}
                style={{ cursor: "pointer" }}
                onClick={() => (window.location.href = "/utts-kayit-sureci")}
              />
              {/* Sol alt %40 genişlik ve %25 yükseklik */}
              {!isMobile && (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "40%",
                    height: "25%",
                    cursor: "pointer",
                    backgroundColor: "rgba(0,0,0,0)", // Görünmez alan
                  }}
                  onClick={() => (window.location.href = "https://utts.gov.tr")}
                ></Box>
              )}
              {/* Alt %15 yükseklik ve %100 genişlik */}
              {isMobile && (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "10%",
                    cursor: "pointer",
                    backgroundColor: "rgba(0,0,0,0)", // Görünmez alan
                  }}
                  onClick={() => (window.location.href = "https://utts.gov.tr")}
                ></Box>
              )}
            </Box>
            <Box>
              <img
                src={require("../assets/img/b2.png")}
                width={"100%"}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  (window.location.href = "/utts-montaj-basvuru")
                }
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Home;
